import DatePicker from 'components/DatePicker';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';
import { Form } from 'react-bootstrap';
import { MeldEnheterUtAvEnhetsgrupperingCommand } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

interface Props {
  command: MeldEnheterUtAvEnhetsgrupperingCommand;
  onCommandUpdated: (command: MeldEnheterUtAvEnhetsgrupperingCommand) => void;
}

const MeldEnheterUtAvEnhetsgrupperingForm = (props: Props) => {
  const handleEnhetsgrupperingChanged = (nodes: IMappedGrupperingNode[]) => {
    if (nodes.length > 0) {
      props.onCommandUpdated({
        ...props.command,
        meldUtAvEnhetsgrupperingNummer: nodes[0].nr,
      });
    }
  };

  const handleTilOgMedChanged = (value: Date | null) => {
    props.onCommandUpdated({
      ...props.command,
      tilOgMedDato: value,
    });
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Velg enhetsgruppering</Form.Label>
        <GrupperingPicker treeSelectMode='radioSelect' onSelectionChanged={(e) => handleEnhetsgrupperingChanged(e)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Utmeldingsdato</Form.Label>
        <DatePicker
          selected={props.command.tilOgMedDato}
          onChange={(value) => handleTilOgMedChanged(value)}
          adjustTimeForTimezoneOffset={true}
        />
      </Form.Group>
    </>
  );
};

export default MeldEnheterUtAvEnhetsgrupperingForm;
