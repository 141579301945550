import { Form } from 'react-bootstrap';
import Style from '../index.module.css';
import { ByttFakturaEpostCommand } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

interface Props {
  command: ByttFakturaEpostCommand;
  onCommandUpdated: (command: ByttFakturaEpostCommand) => void;
}

const ByttFakturaEpostForm = (props: Props) => {

  const handleEpostChanged = (epost: string) => {
    props.onCommandUpdated({
      ...props.command,
      epost: epost,
    });
  };

  return (
    <>
      <Form.Group className={Style.formGroup}>
        <Form.Label>Ny fakturaepost</Form.Label>
        <Form.Control
          type="text"
          value={props.command.epost}
          onChange={(e) => handleEpostChanged(e.target.value)}
        />
      </Form.Group>
    </>
  );
};

export default ByttFakturaEpostForm;
