import { useEffect, useState } from 'react';
import { Form, Modal, Alert } from 'react-bootstrap';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import ByttFakturaAdresseForm from './Forms/ByttFakturaAdresse';
import {
  ByttAdresseCommand,
  ByttFakturaEpostCommand,
  ByttKontonummerCommand,
  FlyttEnheterTilKjedeCommand,
  FlyttEnheterTilNyHovedgrossistCommand,
  MeldEnheterUtAvEnhetsgrupperingCommand,
  MeldEnheterUtvalgInnEnhetsgrupperingCommand,
} from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';
import EnheterBulkApi from 'services/KjederegisteretAdminApi/EnheterBulkApi';
import ByttFakturaEpostForm from './Forms/ByttFakturaEpost';
import ByttKontonummerForm from './Forms/ByttKontoNummer';
import ByttHovedGrossistForm from './Forms/ByttHovedGrossist';
import FlyttEnheterTilKjedeForm from './Forms/FlyttEnheterTilKjede';
import MeldEnheterUtAvEnhetsgrupperingForm from './Forms/MeldUtAvEnhetsgruppering';
import MeldEnheterInnIEnhetsgrupperingForm from './Forms/MeldInnIEnhetsgruppering';

interface Props {
  show: boolean;
  selectedLopeNr: number[];
  onClose: () => void;
}

class BulkJob<TCommand> {
  id: number;
  name: string;
  description: string;
  command: TCommand;
  handleCommand: (command: TCommand) => Promise<void>;

  constructor(
    id: number,
    name: string,
    description: string,
    command: TCommand,
    handleCommand: (command: TCommand) => Promise<void>,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.command = command;
    this.handleCommand = handleCommand;
  }
}

const BulkJobs = [
  new BulkJob<ByttAdresseCommand>(
    0,
    'Bytt fakturaadresse',
    'Bytt fakturaadresse for valgte enheter',
    {
      gateadresse: '',
      postnummer: '',
      adressetype: 'FakturaAdresse',
      ekstraInfo: '',
      ignoreAdresse: false,
      lopenr: [],
    },
    async (command) => {
      await EnheterBulkApi.byttAdresse(command);
    },
  ),
  new BulkJob<ByttFakturaEpostCommand>(
    1,
    'Bytt fakturaepost',
    'Bytt fakturaepost for valgte enheter',
    {
      epost: '',
    },
    async (command) => {
      await EnheterBulkApi.byttFakturaEpost(command);
    },
  ),
  new BulkJob<ByttKontonummerCommand>(
    2,
    'Bytt kontonummer',
    'Bytt kontonummer for valgte enheter',
    {
      kontonummer: '',
    },
    async (command) => {
      await EnheterBulkApi.byttKontonummer(command);
    },
  ),
  new BulkJob<FlyttEnheterTilNyHovedgrossistCommand>(
    3,
    'Flytt til ny hovedgrossist',
    'Flytt valgte enheter til ny hovedgrossist',
    {
      grossistNummer: 0,
      overforKundenummer: false,
    },
    async (command) => {
      await EnheterBulkApi.flyttTilNyHovedgrossist(command);
    },
  ),
  new BulkJob<FlyttEnheterTilKjedeCommand>(
    4,
    'Flytt til ny kjede',
    'Flytt valgte enheter til ny kjede',
    {
      kjedeNummer: 0,
      fraOgMed: new Date(),
      overforKundenummer: false,
    },
    async (command) => {
      await EnheterBulkApi.flyttEnheterTilKjede(command);
    },
  ),
  new BulkJob<MeldEnheterUtAvEnhetsgrupperingCommand>(
    5,
    'Meld ut av enhetsgruppering',
    'Meld valgte enheter ut av enhetsgruppering. Enheter med aktiv tilknyting til valgt enhetsgruppe vil få satt til og med dato på kobling. Enheter uten aktiv tilknytning vil ignoreres', 
    {
      meldUtAvEnhetsgrupperingNummer: -1,
      lopenummer: [],
      tilOgMedDato: new Date(),
    },
     async (command) => {
      await EnheterBulkApi.meldEnheterUtAvEnhetsgruppering(command);
    },
  ),
  new BulkJob<MeldEnheterUtvalgInnEnhetsgrupperingCommand>(
    6,
    'Meld inn i enhetsgruppering',
    'Meld valgte enheter inn i enhetsgruppering. Enheter med aktiv tilknytning i enhetsgruppe med SBSK vil ignoreres', 
    {
      meldInnIEnhetsgrupperingNummer: -1,
      lopenummer: [],
      fraOgMedDato: new Date(),
      skalBetraktesSomKjedemedlemskap: false,
    },
     async (command) => {
      await EnheterBulkApi.meldEnheterInnIEnhetsgruppering(command);
    },
  ),
];

const BulkJobModal = (props: Props) => {
  const [selectedJobId, setSelectedJobId] = useState<number>(-1);
  const [selectedJob, setSelectedJob] = useState<BulkJob<any>>();

  useEffect(() => {
    setSelectedJob(BulkJobs.find((job) => job.id === selectedJobId));
    setJobStatus(JobStatus.NotStarted);
  }, [selectedJobId]);

  useEffect(() => {
    if (selectedJob) {
      setSelectedJob({
        ...selectedJob,
        command: { ...selectedJob.command, lopenummer: props.selectedLopeNr },
      });
    }
  }, [selectedJob]);

  useEffect(() => {
    if (selectedJob) {
      setSelectedJob({
        ...selectedJob,
        command: { ...selectedJob.command, lopenummer: props.selectedLopeNr },
      });
    }
  }, [props.selectedLopeNr]);

  const handleCloseModal = () => {
    setSelectedJobId(undefined);
    props.onClose();
  };

  enum JobStatus {
    NotStarted,
    Started,
    Finished,
    Error,
  }

  const [jobStatus, setJobStatus] = useState<JobStatus>(JobStatus.NotStarted);

  const handleSubmit = async () => {
    try {
      setJobStatus(JobStatus.Started);
      await selectedJob.handleCommand(selectedJob.command);
      setJobStatus(JobStatus.Finished);
    } catch (error) {
      console.error(error);
      setJobStatus(JobStatus.Error);
    }
  };

  const submitDisabled = selectedJobId === -1 || jobStatus === JobStatus.Started;

  return (
    <Modal show={props.show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Bulk endringer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Det er valgt {props.selectedLopeNr.length} enheter</p>
        <Form.Group className="mb-3">
          <Form.Label>Bulkjobb</Form.Label>
          <Form.Select
            value={selectedJobId}
            onChange={(e) => setSelectedJobId(Number(e.target.value))}
          >
            <option value="-1">Velg jobb</option>
            {BulkJobs.map((job) => (
              <option key={job.id} value={job.id}>
                {job.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {selectedJob?.description && <p>{selectedJob.description}</p>}
        {selectedJobId === 0 && !!selectedJob && (
          <ByttFakturaAdresseForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}
        {selectedJobId === 1 && !!selectedJob && (
          <ByttFakturaEpostForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}
        {selectedJobId === 2 && !!selectedJob && (
          <ByttKontonummerForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}
        {selectedJobId === 3 && !!selectedJob && (
          <ByttHovedGrossistForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}
        {selectedJobId === 4 && !!selectedJob && (
          <FlyttEnheterTilKjedeForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}
        {selectedJobId === 5 && !!selectedJob && (
          <MeldEnheterUtAvEnhetsgrupperingForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}
        {selectedJobId === 6 && !!selectedJob && (
          <MeldEnheterInnIEnhetsgrupperingForm
            command={selectedJob.command}
            onCommandUpdated={(c) => setSelectedJob({ ...selectedJob, command: c })}
          />
        )}

        {jobStatus === JobStatus.Started && (
          <Alert variant="info" style={{ marginTop: '0.5rem' }}>
            Jobben er startet. Hvis den tar lenger enn 1 min vil du få tilsendt resultatet via
            epost. <a href="/bulkjobber">Oversikt over kjørte bulkjobber finner du her</a>
          </Alert>
        )}

        {jobStatus === JobStatus.Finished && (
          <Alert variant="success" style={{ marginTop: '0.5rem' }}>
            Jobben er ferdig. <a href="/bulkjobber">Resultatet finner du her</a>
          </Alert>
        )}

        {jobStatus === JobStatus.Error && (
          <Alert variant="danger" style={{ marginTop: '0.5rem' }}>
            En feil oppstod under kjøring av jobben. Prøv igjen senere.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {jobStatus === JobStatus.Finished && (
          <IconButton
            icon="close"
            variant="outline-primary"
            className="col"
            onClick={props.onClose}
          >
            Lukk
          </IconButton>
        )}
        {jobStatus != JobStatus.Finished && (
          <>
            <IconButton
              icon="close"
              variant="outline-primary"
              className="col"
              onClick={props.onClose}
            >
              Avbryt
            </IconButton>
            <IconButton
              className="col"
              icon="disk"
              variant="primary"
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              Utfør
            </IconButton>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BulkJobModal;
