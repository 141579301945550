/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';

/*Third-party module dependencies */
import format from 'date-fns/format';

/*Components */
import JobbDetailsModal from './components/JobbDetailsModal';

/*Shared components */
import PageWrapper from 'components/PageWrapper';

/*Service */
import EnheterBulkApi from 'services/KjederegisteretAdminApi/EnheterBulkApi';
import { BulkJobb, JobbDetails } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';
import CommonLoader from 'components/CommonLoader';

/*IMPORTS END */

const BulkJobber = () => {

  /*useState handles */
  const [bulkJobs, setBulkJobs] = useState<BulkJobb[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState<BulkJobb>();
  const [jobDetails, setJobDetails] = useState<JobbDetails>();

  const [isLoading, setIsLoading] = useState(false);

  /*Functions */
  const loadBulkJobs = async () => {
    setIsLoading(true);
    const response = await EnheterBulkApi.getLastJobs();
    setBulkJobs(response);
    setIsLoading(false);
  };

  const handleShowDetails = async (job: BulkJobb) => {
    setSelectedJob(job);
    const response = await EnheterBulkApi.getDetailsById(job.id);
    setJobDetails(response);
    setShowModal(true);
  };

  useEffect(() => {
    loadBulkJobs();
  }, []);

  /*Content */
  return (
    <PageWrapper isFluid header="Kjørte bulkjobber">
      <Row>
        <Col>
          {isLoading && <CommonLoader />}
          {!isLoading && bulkJobs.length > 0 && <Table hover>
            <thead>
              <tr>
                <th>Start tidspunkt</th>
                <th>Varighet</th>
                <th>Jobb</th>
                <th>Hvem</th>
                <th style={{ textAlign: 'center' }}>Antall enheter endret</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bulkJobs.map((i) => (
                <tr key={i.id}>
                  <td>{format(new Date(i.startTidspunkt), 'dd.MM.yyyy HH.mm.ss')}</td>
                  <td>{`${(i.sluttTidspunkt.getTime() - i.startTidspunkt.getTime()) / 1000
                    } sek`}</td>
                  <td>{i.jobbNavn}</td>
                  <td>{i.startetAv}</td>
                  <td style={{ textAlign: 'center' }}>{i.antallPåvirkedeEnheter}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Button variant='outline-primary' size="sm" onClick={() => handleShowDetails(i)}>
                      Se detaljer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>}
        </Col>
      </Row>

      <JobbDetailsModal
        show={showModal}
        job={selectedJob}
        jobDetails={jobDetails}
        onClose={() => setShowModal(false)}
      />
    </PageWrapper>
  );
};

export default BulkJobber;
