import { Form } from 'react-bootstrap';
import Style from '../index.module.css';
import {
  ByttKontonummerCommand,
} from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

interface Props {
  command: ByttKontonummerCommand;
  onCommandUpdated: (command: ByttKontonummerCommand) => void;
}

const ByttKontonummerForm = (props: Props) => {
  const handleKontoNrChanged = (kontonummer: string) => {
    props.onCommandUpdated({
      ...props.command,
      kontonummer: kontonummer,
    });
  };

  return (
    <>
      <Form.Group className={Style.formGroup}>
        <Form.Label>Nytt kontonummer</Form.Label>
        <Form.Control
          type="text"
          value={props.command.kontonummer}
          onChange={(e) => handleKontoNrChanged(e.target.value)}
        />
      </Form.Group>
    </>
  );
};

export default ByttKontonummerForm;
