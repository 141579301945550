import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Table, Form, Modal, ButtonGroup, InputGroup, Spinner } from 'react-bootstrap';
import querystring from 'query-string';
import { isEqual } from 'lodash';
import Highlighter from "react-highlight-words";

// ref dist file, otherwise it will pick up source which breaks build
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';

import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';

import EnhetStatusIcon from './components/EnhetStatusIcon';
import { EnhetFromSearch, EnhetSearchFilter } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import EnhetSearchPreview from './components/EnhetSearchPreview';
import SimpleCreateEnhet from './components/SimpleCreateEnhet';
import BulkJobModal from './components/BulkJobModal';
import SortingIcon from '../../components/SortingIcon';

import useDebounce from 'utils/hooks/useDebounce';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { IAdresse } from 'components/AddressTypeahead/types';
import EnhetSearchApi from 'services/KjederegisteretAdminApi/EnhetSearchApi';
import useLocalStorage from "utils/hooks/useLocalStorage";
import Style from './index.module.css';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import CommonLoader from 'components/CommonLoader';
import FilterSearch from './components/FilterSearch';
import { format } from 'date-fns';

const EnhetSearch = () => {
    let defaultFilter = new EnhetSearchFilter();

    const location = useLocation();
    const navigate = useNavigate();

    if (location.search && location.search.length > 0) {
        const parsedFilter = querystring.parse(
            location.search.substring(1, location.search.length),
            { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' },
        );

        if (parsedFilter.bransjeFilter)
            defaultFilter.bransjeFilter = ensureArray<any>(parsedFilter.bransjeFilter);

        if (parsedFilter.regionKjedeFilter)
            defaultFilter.regionKjedeFilter = ensureArray<any>(parsedFilter.regionKjedeFilter);

        if (parsedFilter.salgsOgMarkedsforingKjedeFilter)
            defaultFilter.salgsOgMarkedsforingKjedeFilter = ensureArray<any>(
                parsedFilter.salgsOgMarkedsforingKjedeFilter,
            );

        if (parsedFilter.ekskluderMarkedskjeder) {
            defaultFilter.ekskluderMarkedskjeder = parsedFilter.ekskluderMarkedskjeder.toString() === "true";
        }

        if (parsedFilter.grossistFilter) {
            defaultFilter.grossistFilter = ensureArray<any>(parsedFilter.grossistFilter);
        }
        if (parsedFilter.enhetsgrupperingFilter) {
            defaultFilter.enhetsgrupperingFilter = ensureArray<any>(parsedFilter.enhetsgrupperingFilter);
        }
        if (parsedFilter.enhetTypeFilter) {
            defaultFilter.enhetTypeFilter = ensureArray<any>(parsedFilter.enhetTypeFilter);
        }
        if (parsedFilter.criteria) {
            defaultFilter.criteria = parsedFilter.criteria.toString();
        }
        if (parsedFilter.aktivFilter) {
            defaultFilter.aktivFilter = parsedFilter.aktivFilter.toString();
        }
        if (parsedFilter.sistEndretAv) {
            defaultFilter.sistEndretAv = ensureArray<any>(parsedFilter.sistEndretAv);
        }
        if (parsedFilter.mottakerTsKundeId) {
            defaultFilter.mottakerTsKundeId = parsedFilter.mottakerTsKundeId.toString();
        }

        if (parsedFilter.komplettTidspunktFilter) {
            defaultFilter.komplettTidspunktFilter = parsedFilter.komplettTidspunktFilter.toString();
        }

        if (parsedFilter.utenHovedbransjeFilter) {
            defaultFilter.utenHovedbransjeFilter = parsedFilter.utenHovedbransjeFilter.toString();
        }

        if (parsedFilter.glnFilter) {
            defaultFilter.glnFilter = parsedFilter.glnFilter.toString();
        }

        if (parsedFilter.ikkePublisertFilter) {
            defaultFilter.ikkePublisertFilter = parsedFilter.ikkePublisertFilter.toString();
        }

        if (parsedFilter.opprettetFilter) {
            defaultFilter.opprettetFilter = parsedFilter.opprettetFilter.toString();
        }

        if (parsedFilter.sortBy) {
            defaultFilter.sortBy = parsedFilter.sortBy.toString();
        }
        if (parsedFilter.sortByDescending) {
            defaultFilter.sortByDescending = parsedFilter.sortByDescending.toString() === "true";
        }
        defaultFilter.fylker = Array.isArray(parsedFilter.fylker) ? parsedFilter.fylker.map(o => o.toString()) : [];
        defaultFilter.kommuner = Array.isArray(parsedFilter.kommuner) ? parsedFilter.kommuner.map(o => o.toString()) : [];
        defaultFilter.postnr = Array.isArray(parsedFilter.postnr) ? parsedFilter.postnr.map(o => o.toString()) : [];
    }

    const [criteria, setCriteria] = useState<string>(defaultFilter.criteria);

    const handleCriteriaChanged = (q: string) => {

        const trimmed = q.replaceAll(' ', '');

        // if trimmed is numeric set trimmed else set q
        if (!isNaN(Number(trimmed))) {
            // if there is a trailing space we don't want to remove that
            if (q.endsWith(' ')) {
                setCriteria(trimmed + ' ');
            }
            else {
                setCriteria(trimmed);
            }
        } else {
            setCriteria(q);
        }
    }

    const debouncedCriteria = useDebounce(criteria, 500);

    const [filter, setFilter] = useState<EnhetSearchFilter>(defaultFilter);
    const [enheter, setEnheter] = useState<EnhetFromSearch[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    // case sensitive
    const [sortCol, setSortCol] = useLocalStorage<string | undefined>('krSortCol', undefined);
    const [colClicks, setColClicks] = useLocalStorage<number>('krSortColClicks', 0);

    const [showExportModal, setShowExportModal] = useState(false);
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [isLoadingExport, setIsLoadingExport] = useState(false);

    const getValueFromQueryString = (key: string) => {
        const query = querystring.parse(window.location.search);
        return query[key];
    }

    const [visningsModus, setVisningsModus] = useState(getValueFromQueryString('visningsModus') || 'tabell');
    const [searchResponseCount, setSearchResponseCount] = useState<number>();
    const searchTextboxRef = useRef(null);

    const isMountedRef = useIsMountedRef();

    function ensureArray<T>(input: T | T[]) {
        if (!Array.isArray(input)) {
            return [input];
        }
        return input;
    }

    const resetSort = () => {
        setSortCol(undefined);
        setColClicks(0);
    }

    const handleSearchEnheter = async () => {
        setIsSearching(true);
        const response = await EnhetSearchApi.searchEnheter(filter);
        if (isMountedRef.current && response) {
            const mergedEnheterList = enheter.concat(response.items);

            setEnheter((filter.skip > 0 && enheter.length > 0) ? [...mergedEnheterList] : response.items);
            if (checkAll) {
                setSelectedLopenr(mergedEnheterList.map(x => x.lopenr));
            }
            setSearchResponseCount(response.count);
        }
        setIsSearching(false);
    };

    const handleFilterChanged = (newfilter: EnhetSearchFilter) => {
        if (isMountedRef.current && !isEqual(newfilter, filter)) {
            resetSort();
            setFilter({ ...newfilter, skip: 0 });
        }
    };

    const handleLoadMore = () => {
        if (!isSearching && (enheter.length < searchResponseCount) && isMountedRef.current) {
            setFilter({
                ...filter,
                skip: enheter.length
            })
        }
    };

    const handleColSortClicked = (col: string) => {
        if (col === sortCol) {
            if (colClicks === 2) {
                setSortCol(undefined);
            }
            setColClicks(prev => prev === 3 ? 1 : prev + 1);
        } else {
            setSortCol(col);
            setColClicks(1);
        }
    };

    const getRowColor = (enhet: { oppstartsdato?: Date; nedlagtDato?: Date }) => {
        if (enhet.nedlagtDato && enhet.nedlagtDato < new Date()) {
            return 'table-secondary';
        }
        if (enhet.oppstartsdato && enhet.oppstartsdato > new Date()) {
            return 'table-warning';
        }
    };


    const exportResultClicked = async () => {
        setIsLoadingExport(true);
        await EnhetApi.exportToExcel(filter).then(response => {
            const url = URL.createObjectURL(
                new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'enheter.xlsx');
            document.body.appendChild(link);
            link.click();
        });

        setIsLoadingExport(false);
        handleCloseExportModal();
    };

    const handleCloseFilterModal = () => setShowFilterModal(false);
    const openFilterModalClicked = () => setShowFilterModal(true);
    const handleCloseExportModal = () => setShowExportModal(false);

    const handleCloseBulkModal = () => {
        setShowBulkModal(false);
    }

    const [selectedLopenr, setSelectedLopenr] = useState<number[]>([]);
    const [checkAll, setCheckAll] = useState(false);

    const handleCheckAll = (on: boolean) => {
        setCheckAll(on);
        if (on) {
            setSelectedLopenr(enheter.map(x => x.lopenr));
        }
        else {
            setSelectedLopenr([]);
        }
    }

    const isLopenrSelected = (lopenr: number) => selectedLopenr.includes(lopenr);
    const handleLopenrSelected = (lopenr: number) => {
        if (isLopenrSelected(lopenr)) {
            setSelectedLopenr(selectedLopenr.filter(x => x !== lopenr));
        } else {
            setSelectedLopenr(selectedLopenr.concat(lopenr));
        }
    };

    const sortTableColumn = (col: string, clicks: number) => {
        setFilter({
            ...filter,
            skip: 0,
            sortBy: col,
            sortByDescending: clicks === 2,
        })
    };

    useEffect(() => {
        sortTableColumn(sortCol, colClicks);
    }, [sortCol, colClicks]);

    // set focus to input for searchtext on initial load
    useEffect(() => {
        if (searchTextboxRef) {
            searchTextboxRef.current.focus();
        }
    }, []);

    // oppdater url med endringer på filter og visningsmodus
    useEffect(() => {
        if (isMountedRef.current) {
            let qs = '?';

            if (filter) {
                qs = qs + querystring.stringify(filter, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
            }

            if (visningsModus) {
                qs = `${qs}&visningsModus=${visningsModus}`;
            }

            navigate({
                pathname: '/enheter',
                search: qs,
            });
            handleSearchEnheter();

        }
    }, [filter, visningsModus]);

    useEffect(() => {
        setCheckAll(selectedLopenr.length > 0 && enheter.length === selectedLopenr.length);
    }, [selectedLopenr]);

    const [highlightWords, setHighlightWords] = useState<string[]>([]);

    const removeWildcards = (input: string): string => {
        let copy = `${input}`;
        if (copy.startsWith("*") && copy.endsWith("*")) {
            return copy.substring(1, copy.length - 1);
        }
        if (copy.startsWith("*")) {
            return copy.substring(1, copy.length - 1);
        }
        if (copy.endsWith("*")) {
            return copy.substring(0, copy.length - 1);
        }
        return copy;
    }
    const parseHighlightWords = (input: string): string[] => {
        if (!input)
            return [];
        const t = removeWildcards(input).split(' ').filter(o => o.length > 1);
        return t;
    }

    useEffect(() => {
        if (isMountedRef.current) {
            setFilter({ ...filter, criteria: debouncedCriteria, skip: 0 });
            setHighlightWords(parseHighlightWords(debouncedCriteria));
        }
    }, [debouncedCriteria])


    return (
        <div className={`container-fluid`}>
            <h2 className='mt-3 mb-3'>Enhetssøk</h2>
            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showFilterModal && (
                        <FilterSearch filter={{ ...filter }} onFilterChanged={(newFilter: EnhetSearchFilter) => handleFilterChanged(newFilter)} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* button grouping to the right side */}
            <Row className='mt-2'>
                <Col className='text-end'>
                    <IconButton
                        className='me-2'
                        size='sm'
                        icon='export'
                        onClick={() => setShowExportModal(true)}
                        variant="outline-primary"
                    >
                        Eksporter
                    </IconButton>
                    <IconButton
                        size='sm'
                        className='me-2'
                        icon='combine'
                        onClick={() => setShowBulkModal(true)}
                        style={{ minWidth: '150px' }}
                        variant="outline-primary"
                    >
                        Bulk endringer
                    </IconButton>
                </Col>
            </Row>

            <Modal show={showExportModal} onHide={handleCloseExportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eksporter søkeresultat til Excel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Last ned søkeresultat som excel-fil (.xlsx).</span>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon='close' className='col' variant="outline-primary" onClick={handleCloseExportModal}>
                        Avbryt
                    </IconButton>
                    <IconButton icon='export' className='col' variant="primary" onClick={exportResultClicked}>
                        {isLoadingExport && (
                            <span style={{ paddingRight: '.3em' }}>
                                <Spinner as="span" animation='border' size='sm' role='status' aria-hidden="true" />
                            </span>
                        )} Last ned
                    </IconButton>
                </Modal.Footer>
            </Modal>

            <BulkJobModal 
                show={showBulkModal}
                selectedLopeNr={selectedLopenr} 
                onClose={handleCloseBulkModal} 
            />

            <Row className={Style.container}>
                <Col className={Style.firstCol}>
                    <SimpleCreateEnhet />
                    {!showFilterModal && (
                        <FilterSearch filter={{ ...filter }} onFilterChanged={(newFilter: EnhetSearchFilter) => handleFilterChanged(newFilter)} />
                    )}
                </Col>
                <Col>
                    <Row>
                        <Col md="7">
                            <Form.Group>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        type="text"
                                        onChange={e => handleCriteriaChanged(e.target.value)}
                                        value={criteria}
                                        ref={searchTextboxRef}
                                        style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
                                        placeholder="Søk etter enhet..."
                                    />
                                    <InputGroup.Text
                                        style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}
                                    >
                                        {criteria.length > 0 ?
                                            <IcebergIcon icon="close" cursor='pointer'
                                                onClick={() => handleCriteriaChanged('')}
                                            />
                                            : <IcebergIcon icon="search" bold />
                                        }
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Text>
                                    Søk med * støttes (o'lear*, *learys, *leary* gir o'learys - 235* gir 2350). Ellers må søkeord treffe på ett tegn nær (mcdonalds returnerer også mcdonald's), mens tall må treffe eksakt. Metodene kan kombineres (mcdonalds majorst*)
                                </Form.Text>
                            </Form.Group>
                        </Col>

                        <Col style={{ padding: '1rem 0', whiteSpace: 'nowrap' }}>
                            {enheter.length > 0 && searchResponseCount > 0 ?
                                `Viser ${enheter.length} av totalt ${searchResponseCount} treff. ${selectedLopenr.length} enheter valgt.`
                                : 'Ingen treff'}
                        </Col>


                        <Col className='text-end' >
                            <ButtonGroup
                                size='sm'
                                aria-label="Visningsmodus resultater"
                            >
                                <Button
                                    title="Vis resultat i tabell"
                                    variant={visningsModus === 'tabell' ? 'outline-primary' : 'outline-primary'}
                                    onClick={() => setVisningsModus('tabell')}
                                >
                                    <IcebergIcon icon="table" bold />
                                </Button>
                                <Button
                                    title="Vis resultat i liste"
                                    variant={visningsModus === 'liste' ? 'outline-primary' : 'outline-primary'}
                                    onClick={() => setVisningsModus('liste')}
                                >
                                    <IcebergIcon icon="list" bold />
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    <div className={Style.smallScreenSizeFilter}>
                        <Button size="sm" style={{ width: '100%' }} onClick={openFilterModalClicked}>
                            Legg til filter &nbsp;&nbsp;
                            <IcebergIcon icon="filter" size="sm" />
                        </Button>
                    </div>

                    {isSearching && (enheter.length <= 0) && (
                        <Row>
                            <Col className={Style.initialLoadSpinner}>
                                <CommonLoader />
                                <span style={{ fontSize: '16px', paddingTop: '1em', color: 'grey' }}>Henter enheter...</span>
                            </Col>
                        </Row>
                    )}
                    {!isSearching && (enheter.length <= 0) && (
                        <div>
                            <h3>Ingen treff. Husk å konsentrere deg før du prøver igjen. Gjerne uten åpenbare skrivefeil denne gangen</h3>
                            <div>
                                <img
                                    src="/IMG_4753.JPG"
                                    alt="focus"
                                    style={{
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '40%',
                                        maxHeight: '550px',
                                        height: 'auto',
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {(searchResponseCount > 0) && (enheter.length > 0) && (
                        <>
                            <Row style={{ flexWrap: 'nowrap' }}>
                                <Col className={Style.infiniteScroll}>
                                    <InfiniteScroll
                                        pageStart={1}
                                        loadMore={handleLoadMore}
                                        hasMore={enheter.length < searchResponseCount}
                                        threshold={50}
                                        initialLoad={false}
                                        loader={
                                            <div className={Style.loadingInfiniteScroll} key={0}>
                                                <CommonLoader />
                                                <span style={{ fontSize: '16px', paddingTop: '1em', color: 'grey' }}>Henter flere enheter...</span>
                                            </div>
                                        }
                                    >
                                        {visningsModus === 'tabell' && (
                                            <Table responsive hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th><Form.Check checked={checkAll} onChange={() => handleCheckAll(!checkAll)} /></th>
                                                        <th />
                                                        <th onClick={() => handleColSortClicked('Enhetnavn')} scope="col">
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Enhetnavn</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Enhetnavn' || colClicks === 3 ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('HovedBesoksgateadresse')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Gateadresse</span>
                                                                <SortingIcon sortDirection={sortCol !== 'HovedBesoksgateadresse' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('BesokPostNr')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Poststed</span>
                                                                <SortingIcon sortDirection={sortCol !== 'BesokPostNr' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('HovedBransjeDescription')}
                                                            scope="col"
                                                            className="d-none d-lg-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Bransje</span>
                                                                <SortingIcon sortDirection={sortCol !== 'HovedBransjeDescription' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('Organisasjonsnr')}
                                                            scope="col"
                                                            className="d-none d-sm-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Orgnr</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Organisasjonsnr' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('JuridiskNavn')}
                                                            scope="col"
                                                            className="d-none d-md-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Juridisk navn</span>
                                                                <SortingIcon sortDirection={sortCol !== 'JuridiskNavn' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('Gln')}
                                                            scope="col"
                                                            className="d-none d-md-table-cell"
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>GLN</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Gln' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th
                                                            onClick={() => handleColSortClicked('Telefon')}
                                                            scope="col"
                                                            className={Style.lowpriorityCol}
                                                        >
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Tlf</span>
                                                                <SortingIcon sortDirection={sortCol !== 'Telefon' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                        <th onClick={() => handleColSortClicked('PagingKey')} scope="col">
                                                            <div className={Style.sortableCol}>
                                                                <span className={Style.sortableColumnHeader}>Løpenummer</span>
                                                                <SortingIcon sortDirection={sortCol !== 'PagingKey' ? '' : (colClicks === 1 ? 'asc' : 'desc')} />
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {enheter.map(e => (
                                                        <tr
                                                            key={e.enhetID}
                                                            style={{ cursor: 'pointer' }}
                                                            className={getRowColor(e)}
                                                        >
                                                            <td>
                                                                <Form.Check checked={isLopenrSelected(e.lopenr)} onChange={() => handleLopenrSelected(e.lopenr)} />
                                                            </td>
                                                            <td>
                                                                <EnhetStatusIcon enhet={e} />
                                                            </td>
                                                            <td onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <Highlighter
                                                                    className={Style.highlighterWrapper}
                                                                    searchWords={highlightWords}
                                                                    autoEscape={true}
                                                                    textToHighlight={e.enhetnavn}
                                                                />
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.besoksadresser && e.besoksadresser
                                                                    .filter(x => x.erHovedAdresse)
                                                                    .map(a => <Highlighter
                                                                        className={Style.highlighterWrapper}
                                                                        searchWords={highlightWords}
                                                                        autoEscape={true}
                                                                        textToHighlight={a.gateadresse}
                                                                    />)}
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.besoksadresser && e.besoksadresser
                                                                    .filter(x => x.erHovedAdresse)
                                                                    .map(a => a.postnummer + ' ' + a.poststed)}
                                                            </td>
                                                            <td className="d-none d-lg-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <Highlighter
                                                                    className={Style.highlighterWrapper}
                                                                    searchWords={highlightWords}
                                                                    autoEscape={true}
                                                                    textToHighlight={e.latestHovedBransjeDescription} />
                                                            </td>
                                                            <td className="d-none d-sm-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.organisasjonsnr}
                                                            </td>
                                                            <td className="d-none d-md-table-cell" onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <Highlighter
                                                                    className={Style.highlighterWrapper}
                                                                    searchWords={highlightWords}
                                                                    autoEscape={true}
                                                                    textToHighlight={e.juridiskNavn} />
                                                            </td>
                                                            <td className={`d-none d-md-table-cell ${e.glnNedlagtDato ? 'text-danger text-decoration-line-through' : ''}`}
                                                                onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                <span title={`${e.glnNedlagtDato ? `Nedlagt: ${format(e.glnNedlagtDato, 'dd.MM.yyyy')}` : ''}`}>{e.gln}</span>
                                                            </td>
                                                            <td className={Style.lowpriorityCol} onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.telefon}
                                                            </td>
                                                            <td onClick={() => navigate(`/enheter/${e.enhetID}`)}>
                                                                {e.lopenr}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        )}
                                        {visningsModus === 'liste' && (
                                            <div>
                                                {enheter.map(enhet => (
                                                    <EnhetSearchPreview key={enhet.enhetID} enhet={enhet} />
                                                ))}
                                            </div>
                                        )}
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>

            </Row>
        </div>
    );
};

export default EnhetSearch;