import AddressTypeahead from 'components/AddressTypeahead';
import { IAdresse } from 'components/AddressTypeahead/types';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ByttAdresseCommand } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

interface Props {
  command: ByttAdresseCommand;
  onCommandUpdated: (command?: ByttAdresseCommand) => void;
}

const ByttFakturaAdresseForm = (props: Props) => {

  const [address, setAddress] = useState<IAdresse>();

  const toggleIgnoreAdresse = (ignoreAdresse: boolean) => {
    props.onCommandUpdated({
      ...props.command,
      ignoreAdresse: !ignoreAdresse,
    });
  };

  const handleAddressChanged = (address: IAdresse) => {
    if (address) {
      props.onCommandUpdated({
        ...props.command,
        gateadresse: address.gateadresse,
        postnummer: address.postnr,
      });
    }
  };

  const handleAddressExtrainfoChanged = (extraInfo: string) => {
    props.onCommandUpdated({
      ...props.command,
      ekstraInfo: extraInfo,
    });
  };

  return (
    <>
      <Form.Group>
        <Form.Check
          checked={props.command?.ignoreAdresse}
          onChange={() => toggleIgnoreAdresse(props.command.ignoreAdresse)}
          label="Ignorer adresse"
        />
      </Form.Group>
      <Form.Group className='mt-3'>
        <Form.Label>Ny fakturaadresse</Form.Label>
        <AddressTypeahead
          selected={address}
          onChange={(address: IAdresse) => {
            handleAddressChanged(address);
          }}
          disabled={props.command.ignoreAdresse}
        />
      </Form.Group>
      <Form.Group className='mt-3'>
        <Form.Label>Ekstra informasjon</Form.Label>
        <Form.Control
          type="text"
          value={props.command.ekstraInfo}
          onChange={(e) => handleAddressExtrainfoChanged(e.target.value.toUpperCase())}
        />
      </Form.Group>
    </>
  );
};

export default ByttFakturaAdresseForm;
