import DatePicker from 'components/DatePicker';
import { Form } from 'react-bootstrap';
import Style from '../index.module.css';
import { FlyttEnheterTilKjedeCommand } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

interface Props {
  command: FlyttEnheterTilKjedeCommand;
  onCommandUpdated: (command: FlyttEnheterTilKjedeCommand) => void;
}

const FlyttEnheterTilKjedeForm = (props: Props) => {
  const handleFraOgMedChanged = (fraOgMed: Date) => {
    props.onCommandUpdated({
      ...props.command,
      fraOgMed: fraOgMed,
    });
  };

  const handleKjedenummerChanged = (kjedeNummer: string) => {
    props.onCommandUpdated({
      ...props.command,
      kjedeNummer: Number(kjedeNummer),
    });
  };

  const toggleOverforKjedenummer = (overforKundenummer: boolean) => {
    props.onCommandUpdated({
      ...props.command,
      overforKundenummer: overforKundenummer,
    });
  };

  return (
    <>
      <Form.Group className={Style.formGroup}>
        <Form.Label>Fra og med dato</Form.Label>
        <DatePicker
          selected={props.command.fraOgMed}
          onChange={(value) => handleFraOgMedChanged(value)}
          adjustTimeForTimezoneOffset={true}
        />
      </Form.Group>
      <Form.Group className={Style.formGroup}>
        <Form.Label className={Style.secondLabel}>Nytt kjedenummer</Form.Label>
        <Form.Control
          type="text"
          value={props.command.kjedeNummer}
          onChange={(e) => handleKjedenummerChanged(e.target.value)}
        />
        <div className={Style.checkbox}>
          <Form.Check
            checked={props.command.overforKundenummer}
            onChange={(e) => toggleOverforKjedenummer(!props.command.overforKundenummer)}
          />
          <Form.Label>Overfør kundenummere?</Form.Label>
        </div>
      </Form.Group>
    </>
  );
};

export default FlyttEnheterTilKjedeForm;
