import DatePicker from 'components/DatePicker';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';
import { Form } from 'react-bootstrap';
import { MeldEnheterUtvalgInnEnhetsgrupperingCommand } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

interface Props {
  command: MeldEnheterUtvalgInnEnhetsgrupperingCommand;
  onCommandUpdated: (command: MeldEnheterUtvalgInnEnhetsgrupperingCommand) => void;
}

const MeldEnheterInnIEnhetsgrupperingForm = (props: Props) => {
  const handleEnhetsgrupperingChanged = (nodes: IMappedGrupperingNode[]) => {
    if (nodes.length > 0) {
      props.onCommandUpdated({
        ...props.command,
        meldInnIEnhetsgrupperingNummer: nodes[0].nr,
      });
    }
  };

  const handleFraOgMedChanged = (value: Date | null) => {
    props.onCommandUpdated({
      ...props.command,
      fraOgMedDato: value,
    });
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Velg enhetsgruppering</Form.Label>
        <GrupperingPicker treeSelectMode='radioSelect' onSelectionChanged={(e) => handleEnhetsgrupperingChanged(e)} />
      </Form.Group>
      <Form.Group className='mt-3'>
        <Form.Label>Inn dato</Form.Label>
        <DatePicker
          selected={props.command.fraOgMedDato}
          onChange={(value) => handleFraOgMedChanged(value)}
          adjustTimeForTimezoneOffset={true}
        />
      </Form.Group>
      <Form.Group className='mt-3'>
        <Form.Check label="Skal betraktes som kjedemedlemskap" checked={props.command.skalBetraktesSomKjedemedlemskap} onChange={(e) => props.onCommandUpdated({ ...props.command, skalBetraktesSomKjedemedlemskap: e.target.checked })} />
      </Form.Group>
    </>
  );
};

export default MeldEnheterInnIEnhetsgrupperingForm;
