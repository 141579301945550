/*IMPORTS */

/*React & React module dependencies */
import React from 'react';
import { Modal, Row, Col, Table } from 'react-bootstrap';

/*Service */
import { BulkJobb, JobbDetails } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

/*IMPORTS END */

/*Interface */
interface Props {
  show: boolean;
  job: BulkJobb;
  jobDetails: JobbDetails;
  onClose: () => void;
}

const JobbDetailsModal = (props: Props) => {
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.job?.jobbNavn}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.job?.endringsBeskrivelse}</p>
        {props.job?.commandSerialized && (
          <>
            <h5>Kommando</h5>
            <code>
              <pre>{JSON.stringify(JSON.parse(props.job.commandSerialized), null, 2)}</pre>
            </code>
          </>
        )}
        {props.jobDetails && (
          <Table>
            <thead>
              <tr>
                <th>Løpenr</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(props.jobDetails?.updatedEnheter || []).map((i) => {
                return (
                  <tr key={i.lopenr}>
                    <td>{i.lopenr}</td>
                    <td>{i.oldValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default JobbDetailsModal;
