import GrossistPicker from "components/GrossistPicker";
import { Form } from "react-bootstrap";
import Style from '../index.module.css';
import { FlyttEnheterTilNyHovedgrossistCommand } from "services/KjederegisteretAdminApi/EnheterBulkApi/types";

interface Props {
  command: FlyttEnheterTilNyHovedgrossistCommand;
  onCommandUpdated: (command: FlyttEnheterTilNyHovedgrossistCommand) => void;
}

const ByttHovedGrossistForm = (props: Props) => {

    const toggleOverførKundenummer = () => {
        props.onCommandUpdated({
            ...props.command,
            overforKundenummer: !props.command.overforKundenummer,
        });
    };

    const handleGrossistChanged = (grossistNummer: number) => {
        props.onCommandUpdated({
            ...props.command,
            grossistNummer: grossistNummer,
        });
    };

    return (
        <>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Velg grossist</Form.Label>
            <GrossistPicker mode='radioSelect' findPreselectedNodesByNR={() => props.command.grossistNummer ? [props.command.grossistNummer]:[]} onSelectionChanged={e => handleGrossistChanged(e.length > 0 ? e.map(x => x.nr)[0] : -1)} />
            <div className={Style.checkbox}>
              <Form.Check checked={props.command.overforKundenummer} onChange={toggleOverførKundenummer} />
              <Form.Label>Overfør kundenummere?</Form.Label>
            </div>
          </Form.Group>
        </>
    );
}

export default ByttHovedGrossistForm;