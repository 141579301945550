import { useState } from 'react';
import { Form, Button, Modal, InputGroup } from 'react-bootstrap';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import DatePicker from 'components/DatePicker';
import addMinutes from 'date-fns/addMinutes';
import Style from './index.module.css';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { Enhetgruppering, Medlemsgruppe, MeldUtMedlemsgruppeCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import CommonLoader from 'components/CommonLoader';

interface Props {
  enhetsgruppe: Enhetgruppering;
  medlem?: Medlemsgruppe;
  showModal: boolean;
  handleCloseModal: () => void;
}

const AvsluttGruppeMedlemskapModal = (props: Props) => {
  const [selectedUtmeldingsDato, setSelectedUtmeldingsDato] = useState<Date>(addMinutes(new Date(), -new Date().getTimezoneOffset()));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toaster = useToaster();

  const handleSubmit = () => {
    if (!props.medlem) {
      return;
    }

    const command: MeldUtMedlemsgruppeCommand = {
      enhetgrupperingGrupperingID: props.medlem.enhetgrupperingGrupperingID,
      tilOgMedDato: selectedUtmeldingsDato,
    };
    setIsLoading(true);
    EnhetsgrupperingApi.meldUtMedlemsgruppe(props.enhetsgruppe.enhetgrupperingID, command)
      .then(() => {
        toaster.success('Suksess!', 'Gruppen ble meldt ut.');
      })
      .catch(err => {
        toaster.error('Feil! Kunne ikke melde ut gruppe.', HttpErrorHelper.formatError(err));
      })
      .finally(() => {
        props.handleCloseModal();
        setIsLoading(false);
      });
  };

  const submitDisabled = isLoading || !selectedUtmeldingsDato;

  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Meld {props.medlem?.enhetgrupperingNavn} ut av {props.enhetsgruppe.enhetsgrupperingnavn}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Utmeldingsdato</Form.Label>
            <DatePicker
              selected={selectedUtmeldingsDato}
              onChange={(date: Date) => setSelectedUtmeldingsDato(date)}
              adjustTimeForTimezoneOffset={true}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={Style.modalFooter}>
        <div>
          <Button className={Style.closeModalBtn} variant="secondary" onClick={props.handleCloseModal}>
            Lukk
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={submitDisabled}>
            Meld ut
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AvsluttGruppeMedlemskapModal;
